<template>
  <div class="form-topic">
    <a-row :gutter="16">
      <a-col :span="24">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Topic">
              <a-textarea
                :autoSize="{ minRows: 8 }"
                size="large"
                class="w-100"
                :value="data.nama"
                @change="(e) => handleChange(e.target.value, 'nama')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Subtopic">
              <a-textarea
                :autoSize="{ minRows: 8 }"
                size="large"
                class="w-100"
                :value="data.subtopik"
                @change="(e) => handleChange(e.target.value, 'subtopik')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Youtube Videos">
          <template v-if="data.videos.length">
            <div v-for="(video, i) in data.videos" :key="i">
              <a-row :gutter="[16, 16]">
                <a-col :xs="24" :lg="22">
                  <span class="videoThumbnail">
                    <iframe width="100%" height="400px" :src="video"></iframe>
                  </span>
                </a-col>
                <a-col :xs="24" :lg="2">
                  <a-button
                    @click.prevent="deleteVideo(i)"
                    type="danger"
                    icon="close"
                    style="width: 100%; height: 48px"
                  ></a-button>
                </a-col>
              </a-row>
              <hr />
            </div>
          </template>
          <a-row :gutter="[16, 16]">
            <a-col :xs="24" :lg="22">
              <a-input
                v-model="newVideo"
                placeholder="Paste youtube videos link (example: 'https://www.youtube.com/watch?v=WFIPwOPbPk0&t=3s')"
                style="height: 48px"
              />
            </a-col>
            <a-col :xs="24" :lg="2">
              <a-button
                :disabled="!newVideo"
                :type="newVideo ? 'primary' : 'default'"
                @click="addVideo"
                icon="plus"
                style="width: 100%; height: 48px"
              ></a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="Reading Material">
          <ckeditor
            :value="data.brief"
            @input="(e) => handleChange(e, 'brief')"
            :editor="editor"
            :config="editorConfig"
          ></ckeditor>
        </a-form-item>
        <a-form-item label="Attached Materials">
          <template v-if="data.files.length">
            <div
              v-for="file in data.files"
              :key="file.id"
              class="p-3 d-flex align-items-center border rounded mb-3"
            >
              <a-icon class="mr-3" type="file" />
              <span>{{ file.originalname }}</span>
              <!-- <span class="">{{ file }}</span> -->
              <a-icon
                v-if="!file.isDeleted"
                @click="removeFile(file.id)"
                class="ml-auto"
                type="delete"
              />
              <!-- <span class="ml-auto" v-else>Will be deleted</span> -->
            </div>
          </template>
          <a-upload-dragger
            name="fileUpload"
            :multiple="true"
            :file-list="data.newUploadList"
            :before-upload="beforeUpload"
            :remove="handleRemove"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">
              Support for a single or multiple upload. Only with '.pdf' or
              'docx' are allowed to upload.
            </p>
          </a-upload-dragger>
          <!-- <a-input
            class="pt-4 pb-5"
            type="file"
            ref="file"
            placeholder="Upload additional documents or files for this session (https://www.youtube.com/watch?v=WFIPwOPbPk0)"
            style="height: 48px"
          />
          <small class="text-secondary mt-4"
            >You should upload file with the 'pdf' or 'docx' format</small
          > -->
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '@/config'
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newVideo: null,
    }
  },
  methods: {
    handleChange(value, column, type = '') {
      const payload = { value, column, type }
      this.$emit('handle-change', payload)
    },
    addVideo() {
      if (this.data.videos && this.data.videos.length === 5) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Maximum videos that can be uploaded is 5.',
        })
      } else {
        const checkUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        let newVideo = this.newVideo
        if (newVideo.match(checkUrl)) {
          if (newVideo.match(/(?=.*youtube)(?=.*v=)/gi)) {
            newVideo = newVideo.split('v=')[1]
            newVideo = `https://www.youtube.com/embed/${newVideo}`
          } else if (newVideo.match(/youtu.be/gi)) {
            newVideo = newVideo.split('.be/')[1]
            newVideo = `https://www.youtube.com/embed/${newVideo}`
          }
          this.handleChange(newVideo, 'videos', 'push')
          this.newVideo = null
        } else {
          this.$notification.error({
            message: 'Sorry.',
            description: 'Your input is not a link. Please add proper youtube link.',
          })
        }
      }
    },
    deleteVideo(id) {
      this.handleChange(id, 'videos', 'splice')
    },
    removeFile(id) {
      console.log('remove')
      this.handleChange(id, 'files', 'remove')
    },
    handleRemove(file) {
      const index = this.data.newUploadList.indexOf(file)
      this.handleChange(index, 'newUploadList', 'splice')
    },
    beforeUpload(file) {
      const word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const pdf = 'application/pdf'
      // const jpg = 'image/jpeg'
      // const png = 'image/png'
      // const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const allowedTypes = [word, pdf]
      const tooLarge = file.size > config.uploader.MAX_SIZE
      if (allowedTypes.includes(file.type) && !tooLarge) {
        this.handleChange(file, 'newUploadList', 'push')
      } else {
        this.$notification.error({
          message: 'Error',
          description: tooLarge ? `Your file too large. Max size is ${config.uploader.MAX_SIZE / 1000}Kb` : 'Only (DOCX, PDF) are allowed',
        })
      }
      return false
    },
  },
  computed: {
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return {
        ...this.$store.state.ckEditor.editorConfig,
        toolbar: {
          items: [
            'alignment',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'fontColor',
            '|',
            // 'MathType',
            // 'bulletedList',
            'numberedList',
            'blockQuote',
            'link',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
      }
    },
  },
}
</script>

<style lang="scss">
.form-topic {
  .ck-editor__editable_inline {
    min-height: 100px;
  }
}
</style>
